import { useState, useReducer, useContext, useEffect } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { reducer } from "Modules";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";

export const RegistrationForm = ({
  formPart,
  formHeading,
  formLegal,
  timeStamp,
  submitButton,
  setIneligible,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc, saveEntry } = useContext(UserContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const fields = event.register.fields;

  const eligibleZipCodes = JSON.parse(event.register.zipCodes);
  const zips = eligibleZipCodes.map((item) => item.zip);

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  useEffect(() => {
    setSubmitting(false);
  }, [submitButton]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorObject = handleErrors(fields, registration, formPart);

    if (!errorObject) {
      setSubmitting(true);

      registration.formPart = formPart;
      // registration.timeStamp = timeStamp
      await writeToUserSubmissionDoc(registration);
      await saveEntry();
      logLeadSubmission({ formPart, registration });
      // console.log(registration)
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}

      {fields && (
        <RegForm onSubmit={handleSubmit}>
          <Form>
            {fields
              .sort((a, b) => a.order - b.order)
              .filter((field) => {
                return field.formPart === formPart;
              })
              .map((field, index) => {
                let mobileSize = "100%";
                let desktopSize = "100%";
                if (field.span === "half") desktopSize = "calc(45% - 4px)";

                if (
                  field.span !== "full" &&
                  (field.spanMobile === "half" || !field.spanMobile)
                )
                  mobileSize = "calc(50% - 4px)";

                return (
                  <FieldContainer
                    sizeDesktop={desktopSize}
                    sizeMobile={mobileSize}
                    fullSize={field.span === "full"}
                    key={index}
                    order={field.order}
                  >
                    {field.type !== "optIn" && !!field.label && (
                      <Label>{field.label}</Label>
                    )}
                    <Field
                      field={field}
                      value={registration[field.name]}
                      onChange={(value) => handleChange(value, field.name)}
                      error={{
                        status: errors[field.name],
                        message: errors[field.name]?.message,
                      }}
                    />
                  </FieldContainer>
                );
              })}

            {formLegal && (
              <>
                <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>
              </>
            )}
          </Form>

          {submitting ? (
            <RegisterButton>Loading</RegisterButton>
          ) : (
            <RegisterButton type="submit">
              {submitButton ? submitButton : "Submit"}
            </RegisterButton>
          )}
        </RegForm>
      )}
    </>
  );
};

const FormTitle = styled.h3`
  font-style: italic;
  margin: 12px 0;
  font-size: max(1.2rem, 1.1vw);
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 25px;
  row-gap: 24px;
  margin: 12px 0 20px;
  width: 100%;
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeMobile};
  ${(props) => props.order && `order: ${props.order};`}
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 0 1 ${(props) => props.sizeDesktop};
  }
`;

const Label = styled.span`
  color: #4d4d4d;
  font-size: 11px;
  margin-bottom: 5px;
`;

const FormLegal = styled.div`
  font-size: 11px;
  margin: -10px 0 0 25px;
  line-height: 1.5;
  order: 99;
  color: white;

  a,
  a:visited {
    color: white;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  margin: 15px 0 45px 0;
  padding: 3% 25%;
  font-size: max(1rem, 1.2vw);
`;
