import React, { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, UserContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { DateTime } from "luxon";
import { firebase } from "context";
import { useDocumentData } from "react-firebase-hooks/firestore";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);
    const { user } = useContext(UserContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [ shareBtn, setShareBtn] = useState("Share Web Link")

    const [entry, loading] = useDocumentData(
      firebase.firestore().doc(`events/${event?.id}/entries/${user?.uid}`)
    );

    if (loading) {
      return null;
    }

    const isRegistratedToday = entry?.[DateTime.now().toFormat("yyyyLLdd")];

    const registerTime = DateTime.fromFormat(
      "10/09/2019 10:03:00.000 AM",
      "D hh:mm:ss.SSS a"
    ).toISO();

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;
    // logo
    const { brand: brandLogo } = event.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;

    if (registered && isRegistratedToday)
      formStep = event.register.sweeps.length - 1;

      // if (registered) formStep = event.register.sweeps.length - 1;
      console.log(formStep)
    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formLegal,
      submit,
      iconInst,
      iconFB,
    } = event.register.sweeps[formStep];

    let iconFacebook = `${api}/o/${storageDirectory}%2F${iconFB}?alt=media`;
    let iconInstagram = `${api}/o/${storageDirectory}%2F${iconInst}?alt=media`;

    // brand cta
    const { facebook, instagram, shareLink } = event.callToActions.links;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
      heading: CTABrandHeading,
      btnLink: brandCTALink,
    } = event?.callToActions?.brand?.background;
    let CTABrandBDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let CTABrandImg = `${api}/o/${storageDirectory}%2F${CTABrandHeading}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions?.brand?.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // artist background
    const {
      desktop: artistPicDesktop,
      CTAbackground,
      artistCTALink,
      heading: artistCTAHeading,
      // mobile: artistMobile,
    } = event?.callToActions?.artist;
    let artistDesktop = `${api}/o/${storageDirectory}%2F${artistPicDesktop}?alt=media`;
    let CTAArtistImg = `${api}/o/${storageDirectory}%2F${artistCTAHeading}?alt=media`;
    // let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;
    let CTAArtistDesktop = `${api}/o/${storageDirectory}%2F${CTAbackground}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label) => {
      logClick({ label, url });
    };

    const copyLinkHandler = () => {
      navigator.clipboard.writeText("gildanmusic.com/chris-lane");
      setShareBtn("Copied!")
      setTimeout(()=>{setShareBtn("Share Web Link")}, 2000)
    };

  
    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <Content>
            <LogoBox>
              <Link
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => linkClickHandler(brandLogo.link, "Brand Logo")}
              >
                {brandLogoImg && <Logo header src={brandLogoImg} />}
              </Link>
            </LogoBox>

            <ArtistMobileImage desktop={artistDesktop} />

            <ContainerPadding>
              {!postCampaign ? (
                <>
                  {/* ===== CAMPAIGN INTRO ===== */}
                  {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
                  {body && <Body>{ReactHtmlParser(body)}</Body>}
                  {subHeading && (
                    <SubHeading>{ReactHtmlParser(subHeading)}</SubHeading>
                  )}
                   {registered && isRegistratedToday && (
                    <>
                      <MainHeading>
                        Share the sweepstakes with your friends
                      </MainHeading>
                      <LinkContainer>
                      <Link
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(facebook, "Brand CTA social")}
                    >
                      <Logo
                        src={iconFacebook}
                        icon
                      />
                    </Link>
                    <Link
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(instagram, "Brand CTA social")}
                    >
                    <Logo
                      src={iconInstagram}
                      icon
                    />
                     </Link>
                        <ButtonCTA
                          shareLink
                          href={shareLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={copyLinkHandler}
                        >
                          {shareBtn}
                        </ButtonCTA>
                      </LinkContainer>
                    </>
                  )}  
                  {/* {registered && (
                    <>
                      <MainHeading>
                        Share the sweepstakes with your friends
                      </MainHeading>
                      <LinkContainer>
                      <Link
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(facebook, "Brand CTA social")}
                    >
                      <Logo
                        src={iconFacebook}
                        icon
                      />
                    </Link>
                    <Link
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(instagram, "Brand CTA social")}
                    >
                    <Logo
                      src={iconInstagram}
                      icon
                    />
                     </Link>
                        <ButtonCTA
                          shareLink
                          href={shareLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={copyLinkHandler}
                        >
                          {shareBtn}
                        </ButtonCTA>
                      </LinkContainer>
                    </>
                  )} */}

                  {/* ===== REGISTRATION FORM ===== */}
                    {(!registered || !isRegistratedToday) && (
                    <RegistrationForm
                      timeStamp={registerTime}
                      formPart={formStep}
                      formHeading={formHeading}
                      formLegal={formLegal}
                      submitButton={submit}
                      setIneligible={setIneligible}
                    />
                  )}  

{/* {(!registered ) && (
                    <RegistrationForm
                      timeStamp={registerTime}
                      formPart={formStep}
                      formHeading={formHeading}
                      formLegal={formLegal}
                      submitButton={submit}
                      setIneligible={setIneligible}
                    />
                  )} */}
                </>
              ) : (
                <>
                  {/* ===== POST-CAMPAIGN HEADINGS ===== */}
                  {postCampaignHeading && (
                    <HeadingPostCampaign>
                      {ReactHtmlParser(postCampaignHeading)}
                    </HeadingPostCampaign>
                  )}
                  {postCampaignBody && (
                    <BodyPostCampaign>
                      {ReactHtmlParser(postCampaignBody)}
                    </BodyPostCampaign>
                  )}
                  <LinkContainer>
                    <Link
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(facebook, "Brand CTA social")}
                    >
                      <Logo
                        src={iconFacebook}
                        icon
                      />
                    </Link>
                    <Link
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(instagram, "Brand CTA social")}
                    >
                    <Logo
                      src={iconInstagram}
                      icon
                    />
                     </Link>
                  </LinkContainer>
                </>
              )}
            </ContainerPadding>
          </Content>

          <ArtistContent>
            <ArtistImage desktop={artistDesktop} />
            <ArtistContentBottom>
              <BrandCTAContainer desktop={CTABrandBDesktop}>
                <BrandContent>
                  <Logo src={CTABrandImg} cta />
                  <ButtonCTA>
                    <Link
                      href={brandLogo.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(brandLogo.link, "Brand CTA")}
                    >
                      Shop Gildan<span>®</span>
                      </Link>
                  </ButtonCTA>
                </BrandContent>
              </BrandCTAContainer>

              <ArtistCTAContainer desktop={CTAArtistDesktop}>
                <BrandContent>
                  <Logo src={CTAArtistImg} ctaArtist />
                  <ButtonCTA>
                    <Link
                      href={artistCTALink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => linkClickHandler(artistCTALink, "Artist CTA")}
                    >
                      Discover
                    </Link>
                  </ButtonCTA>
                </BrandContent>
              </ArtistCTAContainer>
            </ArtistContentBottom>
          </ArtistContent>
        </Container>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 25px 0;
  gap: 0;
  background-color: #003ea1;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    padding: 0;
    background-color: #003ea1;
  }
`;

const Content = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 40%;
    max-width: 450px;
    margin: 0 auto;
  }
`;

const ContainerPadding = styled.div`
  padding: 0 50px;

  @media (max-width: 950px) {
    padding: 50px;
  }
`;

const LogoBox = styled.div`
  padding: 50px 50px 0 50px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;

  @media (max-width: 950px) {
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
  }
`;

const Link = styled.a`
  /* width: 100%;
  max-width: 80px; */

  /* @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: 120px;
  } */

  span {
    font-size: max(0.7rem, 0.8vw);
    position: relative;
    top: -10px;
  }
`;

const Logo = styled.img`
  width: 50%;
  display: flex;

  @media (max-width: 950px) {
    ${(props) => props.header && "width: 70%; margin: 0 auto"}
  }

  ${(props) => props.cta && "width: 80%"}
  ${(props) => props.icon && "width: 30px; height: 30px; margin-right: 10px;"}
  ${(props) => props.ctaArtist && "width: 70%; margin-bottom: 30px"}
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const ButtonCTA = styled(ButtonPrimary)`
  border: 2px solid white;
  width: 100%;
  margin: 0 auto;
  padding: 3%;
  font-size: max(1.1rem, 1.4vw);

  & > a {
    color: white;
    text-decoration: none;
  }

  & > a :active {
    color: red;
    text-decoration: none;
  }

  ${(props) =>
    props.shareLink &&
    "width: 50%; font-size: 1rem; padding: unset; margin: unset"}

`;

const BrandContent = styled.div`
  width: 100%;
  /* max-width: 450px; */
  margin: 0 auto;
  padding: 40px 40px;
  position: absolute;
  bottom: 0;
`;

const MainHeading = styled.h1`
  margin-top: 35px;
  border-top: 1px solid white;
  font-size: 1.5rem;
  padding: 25px 6vw 10px 0;
  & > span {
    display: block;
    font-size: 20px;
  }
`;

const Heading = styled.h2`
  span {
    font-style: italic;
    margin: 0 3px;
  }
`;
const SubHeading = styled.h3``;
const Body = styled.p`

span {
    font-size: 0.6rem;
    position: relative;
    top: -5px;
  }
  `;

const HeadingPostCampaign = styled(Heading)`
  margin-top: 10vw;

  @media (max-width: 950px) {
    margin-top: 0;
  }
`;
const BodyPostCampaign = styled(Body)``;

const CTAHeading = styled.h2`
  font-size: max(2.3rem, 2vw);
  padding-right: 20%;
`;

const FormLegal = styled.div`
  font-size: 12px;
  margin: 0;
  line-height: 1.6;
  order: 99;
  color: white;

  a,
  a:visited {
    color: white;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;
`;

const PoweredBy = styled.div`
  ${topBorder}
`;

const BrandSecondaryCTA = styled.div`
  ${topBorder}
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
`;

const ArtistContentBottom = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: bottom;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }
`;

const ArtistImage = styled.div`
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 76.25%;

@media (max-width: 950px) {
  display: none;
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 56.25%;

  
}
`;

const ArtistMobileImage = styled.div`
 display: none;
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 76.25%;

@media (max-width: 950px) {
  display: block;
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 76.25%;

  
}
`;

const ArtistCTAContainer = styled.div`
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 40%;
  position: relative;

@media (max-width: 950px) {
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 86.25%;
}
`;

const BrandCTAContainer = styled.div`
  background-image: url(${(props) => props.desktop});
  background-position: center;
  flex: 1;
  background-size: cover;
  padding-top: 40%;
  position: relative;

@media (max-width: 950px) {
  flex: 1;
  /* background-image: url(${(props) => props.mobile}); */
  /* background-position: bottom; */
  background-size: cover;
  padding-top: 86.25%;
}
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 20px;
  margin: 0 0 40px;
  z-index: 1;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
`;
