import React, { createContext, useState, useEffect, FC } from "react";
import { firebase } from "context";
// @ts-ignore
import { DateTime } from "luxon";

interface Props {}

export enum UserType {
  UNREGISTERED = 1,
  REGISTERED,
  MULTILOGIN,
  ADMIN,
}

interface Registration {
  EMAIL?: string;
  FNAME?: string;
  LNAME?: string;
  OPTIN?: boolean;
  PHONE?: string;
  OPTINPHONE?: boolean;
}

interface IUserContext {
  user: firebase.User | null;
  userType: UserType | undefined;
  userSubmissionDoc: firebase.firestore.DocumentSnapshot | undefined;
  writeToUserSubmissionDoc: (registration: Registration) => Promise<void>;
  refreshUserClaims: () => Promise<string | null>;
  checkUserVoteExist: () => Promise<boolean>;
  getUserRegistration: () => Promise<
    firebase.firestore.DocumentData | null | undefined
  >;
  saveEntry: () => Promise<void>;
}

export const UserContext = createContext<IUserContext>(null!);
export const UserProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState(firebase.auth().currentUser);
  const [userType, setUserType] = useState<UserType>();
  const [userSubmissionDocId, setUserSubmissionDocId] = useState<string>();
  const [userSubmissionDoc, setUserSubmissionDoc] = useState<
    firebase.firestore.DocumentSnapshot
  >();

  const eventId = process.env.REACT_APP_EVENT_ID;

  /**
   * Checks the `action` query string and does a signout
   */
  useEffect(() => {
    const url = new URL(window.location.href);
    const signOut = async () => await firebase.auth().signOut();
    if (url.searchParams.get("action") === "signout") {
      signOut();
    }
  }, []);

  /**
   * Sets the authstatechange handler
   */
  useEffect(() => {
    firebase.auth().onAuthStateChanged(handleAuthStateChange);
  }, []);

  /**
   * Handles the sign-in state
   *
   * If the user is not signed in - call signInAnonymously()
   * If the user is signed in - sets the docId for the listener
   */
  useEffect(() => {
    if (!eventId) {
      return;
    }

    if (!user) {
      firebase.auth().signInAnonymously();
    } else {
      setUserSubmissionDocId(`${eventId}#${user.uid}`);
    }
  }, [user, eventId]);

  /**
   * Attach a listener to the user's own submission lead doc.
   * For an unregistered user, this document may not exist yet.
   *
   * Once the document gets changed, it's refreshing the user tokens.
   */
  useEffect(() => {
    if (userSubmissionDocId) {
      try {
        firebase
          .firestore()
          .doc(`lead_submissions/${userSubmissionDocId}`)
          .onSnapshot(async (doc) => {
            setUserSubmissionDoc(doc);
            if (user && doc.exists && doc.get("short_code")) {
              await refreshUserClaims();
            }
          });
      } catch (err) {
        console.log("Error attaching listener", err);
      }
    }
    // eslint-disable-next-line
  }, [userSubmissionDocId, eventId]);

  /**
   * Checks for the user's custom claims - if [`eventId`] exists, set `userType` to the proper enum value
   */
  useEffect(() => {
    if (!eventId) {
      return;
    }

    firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            //console.log("idTokenResult", idTokenResult);
            setUserType(
              idTokenResult?.claims[eventId] || UserType.UNREGISTERED
            );
          })
          .catch((error) => console.log(error));
      }
    });
  }, [eventId]);

  const refreshUserClaims = async () => user && user.getIdToken(true);

  const handleAuthStateChange = (user: firebase.User | null) => {
    setUser(user);
  };

  /**
   * Peforms a direct write to the user's document.
   * A cloud function is triggered on creation that adds the `short_code` key to the document.
   */
  const writeToUserSubmissionDoc = async (registration: Registration) => {
    const timeStamp = firebase.firestore.Timestamp.now();
    try {
      await firebase
        .firestore()
        .collection(`events/${eventId}/lead_submits`)
        .add({ ...registration, uid: user?.uid, timeStamp });
      await firebase
        .firestore()
        .doc(`lead_submissions/${userSubmissionDocId}`)
        .set(
          {
            ...registration,
            timezone: DateTime.now().zoneName,
          },
          {
            merge: true,
          }
        );
    } catch (err) {
      console.error("Error saving submission", err);
    }
  };

  const checkUserVoteExist = async () => {
    if (user) {
      const docId = `events/${process.env.REACT_APP_EVENT_ID}/votes/${user.uid}`;
      const snap = await firebase.firestore().doc(docId).get();
      return snap.exists;
    }
    return false;
  };

  const getUserRegistration = async () => {
    if (userSubmissionDocId) {
      const docId = `lead_submissions/${userSubmissionDocId}`;
      const snap = await firebase.firestore().doc(docId).get();
      return snap.data();
    }
    return null;
  };

  const saveEntry = async () => {
    if (eventId && user) {
      await firebase
        .firestore()
        .doc(`events/${eventId}/entries/${user.uid}`)
        .set(
          {
            [DateTime.now().toFormat("yyyyLLdd")]: 1,
          },
          {
            merge: true,
          }
        );
    }
  };

  const ctx: IUserContext = {
    user,
    userType,
    writeToUserSubmissionDoc,
    userSubmissionDoc,
    refreshUserClaims,
    checkUserVoteExist,
    getUserRegistration,
    saveEntry,
  };

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};
