import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
  preCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/o/${storageDirectory}%2F${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    maxPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer preCampaign={preCampaign}>
            <div>
              {(registration || postCampaign) && (
                <>
                  {brandDisclaimer && (
                    <BrandDisclaimer>
                      {ReactHtmlParser(brandDisclaimer)}
                    </BrandDisclaimer>
                  )}
                  <SweepsDisclaimer>
                    {!registered && (
                      <>
                        {event.endSweeps > time &&
                          sweepsDisclaimer &&
                          ReactHtmlParser(sweepsDisclaimer)}
                        {grandPrizeDisclaimer &&
                          ReactHtmlParser(grandPrizeDisclaimer)}
                      </>
                    )}
                  </SweepsDisclaimer>
                  </>
                  )}
                  <MaxPolicy preCampaign={preCampaign}>{ReactHtmlParser(maxPolicy)}</MaxPolicy>            
            </div>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  /* padding: 0 15px; */
  z-index: 1;

  @media ${(props) => props.theme.maxMedia.medium} {
    /* padding: 0 50px; */
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 40px;
  position: relative;
  background-color: #003ea1;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #003ea1;

    ${(props) => props.preCampaign && "justify-content: center;"}
  }
`;

const Link = styled.a`
  margin: 15px;
  align-self: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0 0 0 50px;
    align-self: start;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 234px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: unset;
    margin-top: 10px;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;

   a {
    color: white;
  }
`;

const MaxPolicy = styled.p`
  margin-top: 0;
 
  & > a {
    color: white;
  }

  @media (max-width: 950px) {

    ${(props) => props.preCampaign && "text-align: center;"}
    /* text-align: center; */
  }
`;

const BrandDisclaimer = styled.div``;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: white;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
